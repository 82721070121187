import { IAppointment } from '../../../models/Appointment'
import { IGPLetter } from '../../../models/GPLetter'
import { IPatient, IPatientFile } from '../../../models/Patient'
import { IPrescription } from '../../../models/Prescription'
import api from '../../api.service'
import { IResponse, IResponsePaginated } from '../types'

// Patients
const list = ({
  search,
  page,
  limit,
  sortMethod,
  sortColumn,
  archived,
}: {
  search?: string | null
  page: number
  limit: number
  sortColumn?: string
  sortMethod?: string
  archived?: boolean
}) => {
  return api.get<IResponsePaginated<IPatient[]>>(`/api/patient/v1/patients`, {
    params: {
      q: search || null,
      page: page,
      limit: limit,
      sort_method: sortMethod || null,
      sort_column: sortColumn || null,
      archived: archived ? 1 : 0,
    },
  })
}

const getSingle = ({ uuid }: { uuid: string }) => {
  return api.get<IResponse<IPatient>>(`/api/patient/v1/patient/${uuid}`)
}

const getAppointments = ({
  search,
  patientId,
  date_start,
  page,
  limit,
  sortMethod,
  sortColumn,
}: {
  search?: string | null
  date_start?: Date | string | null
  patientId?: string | null
  page: number
  limit: number
  sortColumn?: string
  sortMethod?: string
}) => {
  return api.get<IResponsePaginated<IAppointment[]>>(
    `/api/patient/v1/appointments`,
    {
      params: {
        q: search || null,
        page: page,
        limit: limit,
        sort_method: sortMethod || null,
        sort_column: sortColumn || null,
        date_start: date_start || null,
        patientId: patientId || null,
      },
    }
  )
}

const getPatientAppointments = ({ uuid }: { uuid: string }) => {
  return api.get<IResponse<IAppointment[]>>(
    `/api/patient/v1/patient/${uuid}/appointments`
  )
}

const getPatientGpLetters = ({ uuid }: { uuid: string }) => {
  return api.get<IResponse<IGPLetter[]>>(
    `/api/patient/v1/patient/${uuid}/gpletters`
  )
}

const getPatientPrescriptions = ({ uuid }: { uuid: string }) => {
  return api.get<IResponse<IPrescription[]>>(
    `/api/patient/v1/patient/${uuid}/prescriptions`
  )
}

const getPatientDocuments = ({ uuid }: { uuid: string }) => {
  return api.get<IResponse<IPatientFile[]>>(
    `/api/patient/v1/patient/${uuid}/documents`
  )
}

const getPatientDocument = ({
  uuid,
  fileUuid,
}: {
  uuid: string
  fileUuid: string
}) => {
  return api.get<IResponse<string>>(
    `/api/patient/v1/patient/${uuid}/files/${fileUuid}/download`
  )
}

const getPatientReportPdfUrl = (appointmentUuid: string) => {
  return `/api/patient/v1/appointment/${appointmentUuid}/report/pdf`
}

const getPatientGpLetterPdfUrl = (gpLetterUuid: string) => {
  return `/api/patient/v1/gpletter/${gpLetterUuid}/pdf`
}

const getPatientPrescriptionPdfUrl = (prescriptionUuid: string) => {
  return `/api/patient/v1/prescription/${prescriptionUuid}/pdf`
}

const API = {
  list,
  getAppointments,
  getSingle,
  getPatientAppointments,
  getPatientGpLetters,
  getPatientPrescriptions,
  getPatientDocuments,
  getPatientDocument,
  getPatientReportPdfUrl,
  getPatientGpLetterPdfUrl,
  getPatientPrescriptionPdfUrl,
}

export default API
