import api from '../../api.service'
import { IResponse } from '../types'
import PatientsApi from './PatientsApi'

const editProfile = (password: string, passwordConfirm: string) => {
  return api.post<IResponse>(`/api/patient/v1/profile`, {
    password,
    password_confirmation: passwordConfirm,
  })
}

const PatientApi = {
  editProfile,
  patients: PatientsApi,
}

export default PatientApi
