import { IUser } from '../../models/User'
import api from '../api.service'

import AdminApi from './admin'
import PatientApi from './patient'
import { IResponse } from './types'

const ping = () => {
  return api.get(`/api/pingauth`)
}

const me = () => {
  return api.get<IResponse<IUser>>(`/api/me`)
}

const login = (username: string, password: string) => {
  return api.post<
    IResponse<{
      accessToken: string
      refreshToken: string
      expiresAt: string
      user: IUser
    }>
  >(`/api/login`, {
    username,
    password,
    source: 'patient_web',
  })
}

const logout = () => {
  return api.post(`/api/logout`, null)
}

const resetPassword = (email: string) => {
  return api.post(`/api/password/reset`, {
    email,
    source: 'patient_web',
  })
}

const changePassword = (
  email: string,
  token: string,
  password: string,
  passwordConfirm: string
) => {
  return api.post(`/api/password/change`, {
    email,
    token,
    password,
    password_confirmation: passwordConfirm,
  })
}

const downloadFile = async ({
  url,
  fileName = 'file',
}: {
  url: string
  fileName?: string
}) => {
  return api
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', fileName) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      return true
    })
    .catch((err) => {
      throw err
    })
}

export const APIService = {
  ping,
  me,
  login,
  logout,
  resetPassword,
  changePassword,
  downloadFile,
  admin: AdminApi,
  patient: PatientApi,
}

export default APIService
