import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { PatientAppointmentChip } from '../../../atoms/AppointmentChip/AppointmentChip'
import { Avatar } from '../../../atoms/Avatar/Avatar'
import Card from '../../../atoms/Card/Card'
import { LoaderFull } from '../../../atoms/Loader/Loader'
import {
  PageContent,
  PageHeading,
  PageTitleContainer,
} from '../../../components/Layout'
import { IAppointment } from '../../../models/Appointment'
import { IPatient, IPatientFile } from '../../../models/Patient'
import { IPrescription } from '../../../models/Prescription'
import APIService from '../../../services/api'
import { downloadFileLink } from '../../../utils/utils'
import { PatientAppointments } from './components/PatientAppointments/PatientAppointments'
import { PatientDetailsTabs } from './components/PatientDetailsTabs/PatientDetailsTabs'
import { PatientDocuments } from './components/PatientDocuments/PatientDocuments'
import { PatientPrescriptions } from './components/PatientPrescriptions/PatientPrescriptions'
import { StyledLoaderWrapper, StyledPatientCardWrapper } from './styled'
import { PatientGpLetters } from './components/PatientGpLetters/PatientGpLetters'
import { IGPLetter } from '../../../models/GPLetter'

const PatientPage = () => {
  const navigate = useNavigate()
  const { patientId } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isLoadingNextAppointments, setIsLoadingNextAppointments] =
    useState<boolean>(false)
  const [isLoadingAppointments, setIsLoadingAppointments] =
    useState<boolean>(false)
  const [isLoadingGpLetters, setIsLoadingGpLetters] = useState<boolean>(false)
  const [isLoadingPrescriptions, setIsLoadingPrescriptions] =
    useState<boolean>(false)
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false)
  const [patient, setPatient] = useState<IPatient>()
  const [nextAppointments, setNextAppointments] = useState<IAppointment[]>()
  const [documents, setDocuments] = useState<IPatientFile[]>([])
  const [appointments, setAppointments] = useState<IAppointment[]>([])
  const [gpLetters, setGpLetters] = useState<IGPLetter[]>([])
  const [prescriptions, setPrescriptions] = useState<IPrescription[]>([])

  const getNextAppointments = async () => {
    if (patientId) {
      setIsLoadingNextAppointments(true)
      try {
        const dateStart = new Date()
        dateStart.setUTCHours(0, 0, 0, 0)

        const { data } = await APIService.patient.patients.getAppointments({
          page: 1,
          limit: 10,
          patientId: patientId,
          date_start: dateStart.toISOString(),
          sortMethod: 'asc',
        })
        setNextAppointments(data.data.items)
      } catch (error) {}
      setIsLoadingNextAppointments(false)
    }
  }

  const getPatientAppointments = async () => {
    if (patientId) {
      setIsLoadingAppointments(true)
      try {
        const { data } =
          await APIService.patient.patients.getPatientAppointments({
            uuid: patientId,
          })
        setAppointments(data.data)
      } catch (error) {}
      setIsLoadingAppointments(false)
    }
  }

  const getPatientGpLetters = async () => {
    if (patientId) {
      setIsLoadingGpLetters(true)
      try {
        const { data } = await APIService.patient.patients.getPatientGpLetters({
          uuid: patientId,
        })
        setGpLetters(data.data)
      } catch (error) {}
      setIsLoadingGpLetters(false)
    }
  }

  const getPatientPrescriptions = async () => {
    if (patientId) {
      setIsLoadingPrescriptions(true)
      try {
        const { data } =
          await APIService.patient.patients.getPatientPrescriptions({
            uuid: patientId,
          })
        setPrescriptions(data.data)
      } catch (error) {}
      setIsLoadingPrescriptions(false)
    }
  }

  const getPatientDocuments = async () => {
    if (patientId) {
      setIsLoadingDocuments(true)
      try {
        const { data } = await APIService.patient.patients.getPatientDocuments({
          uuid: patientId,
        })
        setDocuments(data.data)
      } catch (error) {}
      setIsLoadingDocuments(false)
    }
  }

  const getPatient = async () => {
    setIsLoading(true)
    if (patientId) {
      try {
        const { data } = await APIService.patient.patients.getSingle({
          uuid: patientId,
        })
        setPatient(data.data)

        // Load resources
        getPatientAppointments()
        getPatientGpLetters()
        getPatientPrescriptions()
        getPatientDocuments()
      } catch (error: any) {
        if (error?.response?.status === 403) {
          navigate('/errors/unauthorized')
        } else if (error?.response?.status === 404) {
          navigate('/errors/page-not-found')
        } else {
        }
      }
    }
    setIsLoading(false)
  }

  const handleDownloadPatientDocument = async (file: IPatientFile) => {
    if (!patient) {
      return
    }

    try {
      const { data: fileRes } =
        await APIService.patient.patients.getPatientDocument({
          uuid: patient.uuid,
          fileUuid: file.uuid,
        })
      downloadFileLink(fileRes.data)
    } catch (error) {}
  }

  useEffect(() => {
    getPatient()
    getNextAppointments()
  }, [])

  return (
    <>
      <Helmet title={`Patient - ${process.env.REACT_APP_NAME}`} />
      {isLoading && (
        <StyledLoaderWrapper>
          <LoaderFull />
        </StyledLoaderWrapper>
      )}
      {!isLoading && patient && (
        <>
          <PageHeading>
            <PageTitleContainer
              title={``}
              // subtitle="Subtitle"
              breadcrumbs={[
                { title: 'Home', url: '/dlnd' },
                { title: 'Patient', url: undefined },
              ]}
            />
          </PageHeading>
          <PageContent className="mb-4">
            <section className="row">
              <div className="col-12 col-lg-8 order-2 order-lg-1">
                <div className="row">
                  <div className="col-12">
                    <Card>
                      <PatientDetailsTabs patient={patient} />
                    </Card>
                  </div>
                  <div className="col-12">
                    <h4 className="mb-4">Clinical diary</h4>
                    <Card>
                      <PatientAppointments
                        patient={patient}
                        appointments={appointments}
                        isLoading={isLoadingAppointments}
                      />
                    </Card>
                  </div>
                  <div className="col-12">
                    <h4 className="mb-4">GP Letters</h4>
                    <Card>
                      <PatientGpLetters
                        patient={patient}
                        gpLetters={gpLetters}
                        isLoading={isLoadingGpLetters}
                      />
                    </Card>
                  </div>
                  <div className="col-12">
                    <h4 className="mb-4">Prescription</h4>
                    <Card>
                      <PatientPrescriptions
                        patient={patient}
                        prescriptions={prescriptions}
                        isLoading={isLoadingPrescriptions}
                      />
                    </Card>
                  </div>
                  <div className="col-12">
                    <h4 className="mb-4">Documents</h4>
                    <Card>
                      <PatientDocuments
                        patient={patient}
                        documents={documents}
                        isLoading={isLoadingDocuments}
                        onDownloadClick={(e) =>
                          handleDownloadPatientDocument(e)
                        }
                      />
                    </Card>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 order-1 order-lg-2">
                <div className="row">
                  <div className="col-12">
                    <Card>
                      <StyledPatientCardWrapper>
                        <Avatar
                          className="mb-3"
                          size="xl"
                          content={`${patient.name.charAt(
                            0
                          )}${patient.surname.charAt(0)}`}
                        />
                        <h4>
                          {patient.name} {patient.surname}
                        </h4>
                        {patient.date_of_birth && (
                          <div className="text-sm text-muted mb-1">
                            {patient.date_of_birth}
                          </div>
                        )}
                        {patient.email && (
                          <div className="mb-1">
                            <a href={`mailto:${patient.email}`}>
                              {patient.email}
                            </a>
                          </div>
                        )}
                        {patient.mobile && (
                          <div className="mb-1">
                            <a href={`tel:${patient.mobile}`}>
                              {patient.mobile}
                            </a>
                          </div>
                        )}
                      </StyledPatientCardWrapper>
                    </Card>
                  </div>
                  <div className="col-12">
                    <Card title="Next appointments">
                      {isLoadingNextAppointments && <LoaderFull />}
                      {!isLoadingNextAppointments &&
                        nextAppointments &&
                        nextAppointments.length > 0 &&
                        nextAppointments.map((appointment) => (
                          <PatientAppointmentChip
                            key={appointment.uuid}
                            appointment={appointment}
                            showPatient={false}
                          />
                        ))}
                      {!isLoadingNextAppointments &&
                        nextAppointments &&
                        nextAppointments.length === 0 && (
                          <p className="text-center">
                            No upcoming appointments found
                          </p>
                        )}
                    </Card>
                  </div>
                </div>
              </div>
            </section>
          </PageContent>
        </>
      )}
    </>
  )
}

export default PatientPage
