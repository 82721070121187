import { format } from 'date-fns'
import { FC } from 'react'
import { ActionButton } from '../../../../../atoms/ActionButton/ActionButton'
import Table from '../../../../../atoms/Table/Table'
import { IPatient } from '../../../../../models/Patient'
import { IPrescription } from '../../../../../models/Prescription'
import APIService from '../../../../../services/api'
import { StyledDoctorCategorySquare } from '../../styled'

interface IProps {
  patient: IPatient
  prescriptions: IPrescription[]
  isLoading?: boolean
}

export const PatientPrescriptions: FC<IProps> = ({
  patient,
  prescriptions = [],
  isLoading,
}) => {
  return (
    <Table
      className="mb-4"
      tableSize="md-2"
      isLoading={isLoading}
      isTableHovered={false}
      noResultsText={'No prescriptions found'}
      tableHead={[
        { title: 'Doctor' },
        { title: 'Category' },
        { title: 'Issued date' },
        { title: '' },
      ]}
      rows={prescriptions.map((prescription, i) => {
        return {
          className: '',
          cols: [
            {
              className: 'text-bold-500',
              children: <>Dr. {prescription.doctor?.full_name}</>,
            },
            {
              className: 'text-bold-500',
              children: (
                <>
                  <StyledDoctorCategorySquare
                    $categoryColor={prescription.doctor?.category?.color}
                  />{' '}
                  {prescription.doctor?.category?.name}
                </>
              ),
            },
            {
              children: (
                <>
                  {prescription.issued_at
                    ? format(new Date(prescription.issued_at), 'dd/MM/yyyy')
                    : null}
                </>
              ),
            },
            {
              className: 'text-right',
              children: (
                <>
                  <ActionButton
                    buttonType="download"
                    className="btn-primary"
                    isAsync={true}
                    onClick={async (e) =>
                      APIService.downloadFile({
                        url: APIService.patient.patients.getPatientPrescriptionPdfUrl(
                          prescription.uuid
                        ),
                      })
                    }
                  />
                </>
              ),
            },
          ],
        }
      })}
    />
  )
}
