import { IDoctorProfile } from './Doctor'

export enum UserType {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  Doctor = 'doctor',
  Secretary = 'secretary',
  Patient = 'patient',
}

export type UserRoleType =
  | 'superadmin'
  | 'admin'
  | 'doctor'
  | 'secretary'
  | 'patient'

export interface IUser {
  id: number
  uuid: string
  name: string
  surname: string
  full_name: string
  role: IUserRole
  email: string
  phone: string | null
  is_leaving?: boolean
  active?: boolean
  signature?: string | null
  signature_file?: string | null
  doctor_profile?: IDoctorProfile
  is_admin: boolean
  is_management: boolean
  is_secretary: boolean
  is_doctor: boolean
  is_patient: boolean
  patients_count?: number
}

export interface IUserRole {
  id: number
  name: UserRoleType
  title: string
}
